import React, { useEffect, useRef, useState } from 'react';
import bemCn from 'bem-cn';
import defImgSpot from '../../assets/img/host-2.png';
import { EVENTS_NAME } from '../../consts/event.consts';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import { ImageHelper } from '../../classes/image-helper.class';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import { scrollToElement } from '../../helpers/common';
import { showPrice } from '../../helpers/price';
import { ENCLOSURE_TYPE, SIGN_UP_SRC, SPOT_MIN_REQ_PHOTOS, SPOT_REVIEWS_SORT_CONTENT, SPOT_SRC, SPOT_SRC_MEMBERSHIP } from '../../consts/spot.consts';
import {
    LOW_CLEAN_THRESHOLD,
    LOW_FENCING_THRESHOLD,
    LOW_PHOTOS_THRESHOLD,
    LOW_RATING_THRESHOLD,
    RESERVATION_TYPE,
} from '../../consts/reservation.consts';
import ListingHost from '../../components/listing-host';
import ListingRules from '../../components/listing-rules';
import ListingExtras from '../../components/listing-extras';
import ListingPresent from '../../components/listing-present';
import ListingReviews from '../../components/listing-reviews';
import ListingLocation from '../../components/listing-location/index-ssr';
import ListingAmenities from '../../components/listing-amenities';
import ListingAvailability from '../../components/listing-availability';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import RenderMobilePhotoGallery from './render-mobile-photo-gallery';
import { sortByObjTitle } from '../../helpers/reservation';
import RenderUserIsMember from './render-user-is-member';
import RenderModalWarning from './render-modal-warning';
import { RouteFormatter } from '../../routes';
import GuestSpotCalendar from '../../components/calendar/guest-calendar-ssr';
import RenderNearbySpots from './render-nearby-spots-ssr';
import RenderHeadDesktop from './render-head-desktop';
import SeoLocalBusiness from '../../components/seo-local-business';
import ReservationPanel from '../../components/reservation-panel';
import DesktopBookBlock from './desktop-book-block';
import RenderHeadMobile from './render-head-mobile';
import RenderSpotBadge from './render-spot-badge';
import ListingFencing from '../../components/listing-fencing';
import MobileSubPage from '../../components/mobile-sub-page';
import CookieService from '../../services/cookie.service';
import ModalMobile from '../../components/modal-mobile';
import SniffButton from '../../components/sniff-button';
import RenderPrice from './render-price';
import SvgCross2 from 'src/assets/svg/SvgCross2.svg';
import SvgMembership from 'src/assets/svg/SvgMembership.svg';
import SvgSubscription from 'src/assets/svg/SvgSubscription.svg';
import './index.scss';

const b = bemCn('memberships-modal-spot-details');
const bs = bemCn('sniffpass-modal-spot-details');

const useMutateTrackEvent = require('../../services/hooks/useMutateTrackEvent');

const favSpots = [];

const SpotViewPage = ({
    spot,
    userDetails,
    useCallback,
    usePrevious,
    useGetAvailableTimes,
    useLazyGetIntentCal,
    useUpdateIntent,
    useMutateFavorites,
    useGetShareLink,
    useAddReply,
    useDeleteReply,
    useHandleUpvote,
    useGetSpotReviews,
    useOnScreen,
    useGetReviews,
    Select,
    serverSSR,
}) => {
    const isMobile = useMobileScreen();
    const hostRef = useRef(null);
    const reviewsRef = useRef(null);
    const { getReviews, dataReviews } = serverSSR ? {} : useGetReviews();
    const { trackEvent } = serverSSR ? {} : useMutateTrackEvent.default();
    const [sortBy, setSortBy] = useState('COMMENT');
    const [revLimit, setRevLimit] = useState(10);
    const [memberSrc, setMemberSrc] = useState(null);
    const [sortReviews, setSortReviews] = useState(SPOT_REVIEWS_SORT_CONTENT.COMMENT);
    const [showCalendar, setShowCalendar] = useState((typeof window !== 'undefined' && userDetails?.id && window.location.hash?.substring(1)) || '');
    const [openSniffpass, setOpenSniffpass] = useState(false);
    const [openMembership, setOpenMembership] = useState(false);
    const [singleViewPhoto, setSingleViewPhoto] = useState(null);
    const [mobilePhotoCount, setMobilePhotoCount] = useState(0);
    const [desktopPhotoCount, setDesktopPhotoCount] = useState(0);
    const [mobilePhotoGallery, setMobilePhotoGallery] = useState(false);
    const [desktopPhotoGallery, setDesktopPhotoGallery] = useState(false);
    const [warning, setWarning] = useState({
        isOpen: false,
        isLowRating: false,
        isLongInactive: false,
        type: null,
    });
    const isCanReqPhotos = spot.spotPhotos.length < SPOT_MIN_REQ_PHOTOS;

    const listBread = [
        {
            name: 'All dog parks',
            url: RouteFormatter.listings({}),
        },
        {
            name: spot?.state || '',
            url: RouteFormatter.listings({ first: spot?.stateUrl }) || '',
        },
        {
            name: spot?.city || '',
            url: spot?.cityUrl || '',
        },
        {
            name: spot?.title || '',
        },
    ];

    useEffect(() => {
        const htmlEle = document.documentElement;

        if (mobilePhotoGallery) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            htmlEle.classList.add('root-html-focused');
        } else {
            setMobilePhotoCount(0);
            htmlEle.classList.remove('root-html-focused');
        }

        return () => htmlEle.classList.remove('root-html-focused');
    }, [mobilePhotoGallery]);

    useEffect(() => {
        trackEvent({ name: EVENTS_NAME.spot_detail_view });
    }, []);

    const changeSort = (key, force) => {
        setSortBy(key);
        setRevLimit(10);
        getReviews({
            variables: {
                id: spot.id,
                sortBy: SPOT_REVIEWS_SORT_CONTENT[key].sortBy,
                sortOrder: SPOT_REVIEWS_SORT_CONTENT[key].sortOrder,
                first: 10,
            },
            fetchPolicy: force ? 'network-only' : 'cache-and-network',
        });
        setSortReviews(SPOT_REVIEWS_SORT_CONTENT[key]);
    };

    const onAskQuestion = ({ preText }) => {
        const spotId = spot.id;

        if (!userDetails.id) {
            CookieService.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, SIGN_UP_SRC.MESSAGE_HOST_WEB, { expires: 1 });
        }

        if (preText) {
            window.location = RouteFormatter.guestConversation({ channelId: spotId }, { query: { preText } });
        } else {
            window.location = RouteFormatter.guestConversation({ channelId: spotId });
        }
    };

    const singlePhotoHandler = (id) => {
        setMobilePhotoCount(id + 1);
        setSingleViewPhoto(id);
    };

    const toggleDesktopPhotoGallery = (idx = 0) => {
        setDesktopPhotoGallery(!desktopPhotoGallery);
        setDesktopPhotoCount(idx);
    };

    const handleNextDesktopGallery = () => {
        let newIdxPhoto = desktopPhotoCount;

        if ((isCanReqPhotos && newIdxPhoto == spot.spotPhotos.length) || (!isCanReqPhotos && newIdxPhoto + 1 == spot.spotPhotos.length)) {
            newIdxPhoto = 0;
        } else {
            newIdxPhoto++;
        }

        setDesktopPhotoCount(newIdxPhoto);
    };

    const handleBackDesktopGallery = () => {
        let newIdxPhoto = desktopPhotoCount;

        if (newIdxPhoto - 1 < 0) {
            newIdxPhoto = isCanReqPhotos ? spot.spotPhotos.length : spot.spotPhotos.length - 1;
        } else {
            newIdxPhoto--;
        }

        setDesktopPhotoCount(newIdxPhoto);
    };

    const handleSwipeMobile = (newSwipe) => setMobilePhotoCount(newSwipe);
    const toggleOpenMembership = () => setOpenMembership(!openMembership);
    const toggleOpenSniffpass = () => setOpenSniffpass(!openSniffpass);

    const handleMembershipBadge = () => {
        if (spot.isMember) {
            window.location = RouteFormatter.subscriptions();
            return;
        }
        setMemberSrc(SPOT_SRC_MEMBERSHIP.WEB_DETAIL_BADGE);
        toggleOpenMembership();
    };

    const handleSniffpassBadge = () => {
        setMemberSrc(SPOT_SRC_MEMBERSHIP.WEB_DETAIL_BADGE);
        toggleOpenSniffpass();
    };

    const handleMembershipReviewBadge = (e) => {
        e.stopPropagation();
        if (spot.isMember) {
            window.location = RouteFormatter.subscriptions();
            return;
        }
        setMemberSrc(SPOT_SRC_MEMBERSHIP.WEB_REVIEW_BADGE);
        toggleOpenMembership();
    };

    const handleBook = async (type) => {
        setMobilePhotoGallery(false);
        setDesktopPhotoGallery(false);

        if (!userDetails?.id) {
            const addedSrc = type == RESERVATION_TYPE.BOOK ? SPOT_SRC.WEB_SPOT : SPOT_SRC.WEB_CALENDAR;
            const signSrc = type == RESERVATION_TYPE.BOOK ? SIGN_UP_SRC.CHECKOUT_WEB : SIGN_UP_SRC.CALENDAR_WEB;
            const redirectUrl = `${window.location.href}#${addedSrc}`;
            CookieService.set(COOKIE_PARAM_NAME.REDIRECT, redirectUrl, { expires: 1 });
            CookieService.set(COOKIE_PARAM_NAME.SIGN_UP_FROM, signSrc, { expires: 1 });
            window.location = RouteFormatter.signUp();
            return;
        }

        const isLowRating = spot.rating !== null ? spot.rating < LOW_RATING_THRESHOLD : false;
        const isLongInactive = spot.inactiveWarning;
        const isFencingIssue =
            spot.enclosureType === ENCLOSURE_TYPE.FULLY_FENCED && spot.fencingPercentage !== null && spot.fencingPercentage < LOW_FENCING_THRESHOLD;
        const isCleanIssue = spot.cleanlinessPercentage && spot.cleanlinessPercentage < LOW_CLEAN_THRESHOLD;
        const isPhotosIssue = spot.allPhotos.length < LOW_PHOTOS_THRESHOLD;

        if (isLowRating || isLongInactive || isFencingIssue || isCleanIssue || isPhotosIssue) {
            if (!userDetails.id || (userDetails.id && !spot?.hasBookedBefore)) {
                setWarning({
                    isOpen: true,
                    isLowRating,
                    isLongInactive,
                    isFencingIssue,
                    isCleanIssue,
                    isPhotosIssue,
                    type,
                    SSR: true,
                });
                return;
            }
        }

        switch (type) {
            case RESERVATION_TYPE.BOOK:
                window.location.hash = SPOT_SRC.WEB_SPOT;
                setShowCalendar(SPOT_SRC.WEB_SPOT);
                break;
            case RESERVATION_TYPE.CALENDAR:
                window.location.hash = SPOT_SRC.WEB_CALENDAR;
                setShowCalendar(SPOT_SRC.WEB_CALENDAR);
                break;
            default:
                break;
        }
    };

    const goToReviews = () => reviewsRef.current && scrollToElement(reviewsRef.current, isMobile);
    const goToHost = () => hostRef.current && scrollToElement(hostRef.current, isMobile);

    const goToNewSniffpass = () => {
        window.location = RouteFormatter.subscriptionsNew(null, { query: { src: memberSrc } });
    };

    const goToMemberPage = () => {
        window.location = RouteFormatter.membershipsDetail({ id: spot.id }, { query: { src: memberSrc } });
    };

    const moreHandler = () => {
        const newRevLimit = revLimit + 10 > spot.reviewsCount ? spot.reviewsCount : revLimit + 10;
        getReviews({
            variables: {
                id: spot.id,
                sortBy: SPOT_REVIEWS_SORT_CONTENT[sortBy].sortBy,
                sortOrder: SPOT_REVIEWS_SORT_CONTENT[sortBy].sortOrder,
                first: newRevLimit,
            },
        });
        setRevLimit(newRevLimit);
    };

    const handleCloseCal = () => {
        window.location.hash = '';
        setShowCalendar('');
    };

    const spotSrc = ImageHelper.getUrlFromOptions(spot.allPhotos.length ? spot.allPhotos[0] : defImgSpot, `fit-in/${IMG_RESIZES.x0354}`);

    if (mobilePhotoGallery) {
        return (
            <RenderMobilePhotoGallery
                {...{
                    spot,
                    singleViewPhoto,
                    singlePhotoHandler,
                    setMobilePhotoCount,
                    setMobilePhotoGallery,
                    setSingleViewPhoto,
                    mobilePhotoCount,
                    handleBook,
                    handleSwipeMobile,
                }}
            />
        );
    }

    return (
        <>
            <SeoLocalBusiness spot={spot} withReviews={spot.reviews} />
            <div className="spot-view-page container">
                <div className="spot-detail-block">
                    <div className="head-container">
                        <div className="d-md-none">
                            <RenderHeadMobile
                                {...{
                                    spot,
                                    setMobilePhotoGallery,
                                    handleSwipeMobile,
                                    mobilePhotoCount,
                                    listBread,
                                    goToReviews,
                                    goToHost,
                                    handleBook,
                                    handleMembershipBadge,
                                    handleSniffpassBadge,
                                    isMobile,
                                    useState,
                                    useEffect,
                                    serverSSR,
                                }}
                            />
                        </div>
                        <div className="d-none d-md-block">
                            <RenderHeadDesktop
                                SSR
                                {...{
                                    listBread,
                                    spot,
                                    goToHost,
                                    goToReviews,
                                    toggleDesktopPhotoGallery,
                                    desktopPhotoCount,
                                    desktopPhotoGallery,
                                    handleNextDesktopGallery,
                                    handleBackDesktopGallery,
                                    handleMembershipBadge,
                                    handleSniffpassBadge,
                                    isMobile,
                                    useState,
                                    useEffect,
                                    useCallback,
                                    favSpots,
                                    useMutateFavorites,
                                    userDetails,
                                    useGetShareLink,
                                    setDesktopPhotoCount,
                                }}
                            />
                        </div>
                    </div>
                    <ListingFencing
                        isMobileSSR={isMobile}
                        useStateSSR={useState}
                        encls={spot.enclosureType}
                        fncHeight={spot.fenceHeight}
                        gaps={spot.gaps}
                        fencingType={spot.fencingType}
                        fencingDetail={spot.fencingDetail}
                        fencingPercentage={spot.fencingPercentage}
                    />
                    <ListingPresent
                        anmlsPrsnt={spot.domesticAnimalsPresent}
                        dogsPrsnt={spot.dogsPresent}
                        pplePrsnt={spot.peoplePresent}
                        dogsAway={spot.dogsAway}
                        peopleAway={spot.peopleAway}
                        prvtEntry={spot.privateEntry}
                        describePrivateEntry={spot.describePrivateEntry}
                        describeDomesticAnimalsPresent={spot.describeDomesticAnimalsPresent}
                        describeDogsPresent={spot.describeDogsPresent}
                        describePeoplePresent={spot.describePeoplePresent}
                        dogsPercentage={spot.dogsPercentage}
                        otherAnimalsPercentage={spot.otherAnimalsPercentage}
                        peoplePercentage={spot.peoplePercentage}
                        useStateSSR={useState}
                        isMobile={isMobile}
                        SSR
                    />
                    <ListingAmenities
                        isMobileSSR={isMobile}
                        useEffectSSR={useEffect}
                        useStateSSR={useState}
                        dogsAmenities={spot.dogsAmenities}
                        essentialAmenities={spot.essentialAmenities}
                        peopleAmenities={spot.peopleAmenities}
                        venueAmenities={spot.venueAmenities}
                    />
                    <RenderPrice SSR {...{ isMobile, spot }} />
                    <ListingLocation
                        isMobileSSR={isMobile}
                        useStateSSR={useState}
                        latitude={spot.latitude}
                        longitude={spot.longitude}
                        dscrpt={spot.description}
                        hazards={spot.hazards}
                        clean={spot.cleanlinessPercentage}
                        city={spot.city}
                        state={spot.state}
                        distanceFrom={spot.distanceFrom}
                    />
                    <ListingExtras
                        spot={spot}
                        extras={spot.extras ? [...spot.extras].sort(sortByObjTitle) : []}
                        isMobileSSR={isMobile}
                        useEffectSSR={useEffect}
                        useStateSSR={useState}
                    />
                    <ListingRules
                        useEffectSSR={useEffect}
                        useStateSSR={useState}
                        isMobileSSR={isMobile}
                        breedSizeRestrictions={spot.breedSizeRestrictions}
                        hostPresencePreference={spot.hostPresencePreference}
                        reservationDelayTime={spot.reservationDelayTime}
                        maximumDogsAllowed={spot.maximumDogsAllowed}
                        minimumLength={spot.minimumLength}
                        instantBook={spot.instantBook}
                        rules={spot.rules}
                        avatar={spot.host.avatarAws}
                    />

                    {/* RENDER CALENDAR */}
                    {showCalendar ? (
                        isMobile ? (
                            <>
                                <ListingAvailability {...{ isMobile, handleBook }} />
                                <MobileSubPage
                                    show={isMobile && !!showCalendar}
                                    className="calendar"
                                    title="Spot availability"
                                    onBack={handleCloseCal}
                                    useEffectSSR={useEffect}
                                    useStateSSR={useState}
                                >
                                    <GuestSpotCalendar
                                        {...{
                                            spotDetails: spot,
                                            isMobile,
                                            spotId: spot.id,
                                            showCalendar,
                                            useEffect,
                                            useState,
                                            usePrevious,
                                            useGetAvailableTimes,
                                            useLazyGetIntentCal,
                                            useUpdateIntent,
                                            userDetails,
                                            Select,
                                            trackEvent,
                                        }}
                                    />
                                </MobileSubPage>
                            </>
                        ) : (
                            <ModalMobile isOpen={!!showCalendar} toggle={handleCloseCal} title="Spot availability" className="spot-avail-desktop">
                                <GuestSpotCalendar
                                    {...{
                                        spotDetails: spot,
                                        isMobile,
                                        spotId: spot.id,
                                        showCalendar,
                                        useEffect,
                                        useState,
                                        usePrevious,
                                        useGetAvailableTimes,
                                        useLazyGetIntentCal,
                                        useUpdateIntent,
                                        userDetails,
                                        Select,
                                        trackEvent,
                                    }}
                                />
                            </ModalMobile>
                        )
                    ) : (
                        ''
                    )}
                    {/*  */}

                    {/* RENDER WARNING BEFORE BOOK */}
                    <RenderModalWarning
                        {...{
                            warning,
                            setWarning,
                            spotRating: spot.rating?.toPrecision(2),
                            onAskQuestion,
                            setShowCalendar,
                            useState,
                        }}
                    />
                    {/*  */}

                    <div ref={reviewsRef}>
                        <ListingReviews
                            isMobile={isMobile}
                            spot={spot}
                            host={spot.host}
                            isTop={spot.top}
                            spotId={spot.id}
                            rating={spot.rating}
                            reviews={spot.reviews}
                            changeSort={changeSort}
                            moreHandler={moreHandler}
                            sortReviews={sortReviews}
                            reviewsCount={spot.reviewsCount}
                            handleMembershipReviewBadge={handleMembershipReviewBadge}
                            useEffectSSR={useEffect}
                            useStateSSR={useState}
                            useRefSSR={useRef}
                            useAddReplySSR={useAddReply}
                            useDeleteReplySSR={useDeleteReply}
                            useHandleUpvoteSSR={useHandleUpvote}
                            useGetSpotReviewsSSR={useGetSpotReviews}
                            useOnScreenSSR={useOnScreen}
                            userDetails={userDetails}
                            dataReviews={dataReviews}
                            sortBy={sortBy}
                            serverSSR={serverSSR}
                        />
                    </div>

                    <div ref={hostRef}>
                        <ListingHost
                            hostId={spot.host.id}
                            nameAndInitial={spot.host.nameAndInitial}
                            avatar={spot.host.avatarAws}
                            info={spot.host.aboutHost}
                            email={spot.host.email}
                            joinDate={spot.host.createdAt}
                            acceptanceRate={spot.acceptanceRate}
                            responseTime={spot.responseTime}
                            instantBook={spot.instantBook}
                            isMobile={isMobile}
                            useStateSSR={useState}
                            serverSSR={serverSSR}
                        />
                    </div>
                    <div className="ask-container mx-2 mx-md-0">
                        <div className="row ask-row">
                            <div className="col-md-12">
                                <SniffButton className="mb-3" color="secondary" size="lg" onClick={onAskQuestion}>
                                    Ask the host a question
                                </SniffButton>
                            </div>
                        </div>
                    </div>
                    <div className="d-md-none">
                        <RenderNearbySpots SSR {...{ spot, useEffect, useState, usePrevious }} />
                    </div>
                    {!showCalendar && (
                        <ReservationPanel
                            commentsNmb={spot.reviewsCount}
                            title={spot.title}
                            rating={spot.rating}
                            price={spot.price}
                            instantBook={spot.instantBook}
                            live={spot.live && spot.host.id > 0}
                            handleBook={handleBook}
                        />
                    )}
                </div>
                <div className={`desktop-book-block ${!spot.live || spot.host.id == 0 ? 'disabled' : ''}`}>
                    <div className="desktop-book-block__content">
                        <DesktopBookBlock {...{ spot, handleBook, useState }} />
                    </div>
                    <RenderSpotBadge {...{ spot }} />
                    {spot.isMember && <RenderUserIsMember SSR />}
                </div>
            </div>
            <div className="d-none d-md-block">
                <RenderNearbySpots SSR {...{ spot, useEffect, useState, usePrevious }} />
            </div>
            <div className="d-md-none spot-view-margin" />
            {spot.offerMembership && spot.activeMembership && (
                <ModalMobile isOpen={openMembership} toggle={toggleOpenMembership}>
                    <div className={b('header')}>
                        <div className={b('close')} onClick={toggleOpenMembership}>
                            <SvgCross2 />
                        </div>
                    </div>
                    <div className={b('content')}>
                        <div className={b('thumbnail')}>
                            <img src={spotSrc} alt="Spot photo" />
                            <SvgMembership />
                        </div>
                        <p className="snif-p snif-semibold mt-2">Become a member!</p>
                        <p className="snif-s1 mt-1 pt-05">Support this host and access member-only hours with a fixed monthly price.</p>
                        <p className="snif-s1 mt-3">Starting at</p>
                        <p className="snif-l2 snif-semibold">{showPrice(spot.memberPrice2Hour)}</p>
                        <p className="snif-s2 snif-medium mt-05">per dog per month</p>
                        <SniffButton className={b('cta')} size="lg" block onClick={goToMemberPage}>
                            Learn more
                        </SniffButton>
                    </div>
                </ModalMobile>
            )}
            <ModalMobile isOpen={openSniffpass} toggle={toggleOpenSniffpass}>
                <div className={bs('header')}>
                    <div className={bs('close')} onClick={toggleOpenSniffpass}>
                        <SvgCross2 />
                    </div>
                </div>
                <div className={bs('content')}>
                    <div className={bs('thumbnail')}>
                        <SvgSubscription />
                    </div>
                    <p className="snif-p snif-semibold mt-2">Become a Sniffpass holder!</p>
                    <p className="snif-s1 mt-1 pt-05 px-md-3">
                        Get discounts on all bookings at all spots by buying monthly credits! This spot offers additional discounts.
                    </p>

                    <p className="snif-s1 mt-3">Starting at</p>
                    <p className="snif-l2 snif-semibold">{showPrice(20)}</p>
                    <p className="snif-s2 snif-medium mt-05">per month</p>
                    <SniffButton className={bs('cta')} size="lg" block onClick={goToNewSniffpass}>
                        Learn more
                    </SniffButton>
                </div>
            </ModalMobile>
        </>
    );
};

export default SpotViewPage;

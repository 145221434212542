import React, { useState } from 'react';
import DesignedForPrivate from '../designed-for-private';
import { Present } from './present';
import './index.scss';

const ListingPresent = ({
    dogsPrsnt,
    pplePrsnt,
    anmlsPrsnt,
    describeDogsPresent,
    describeDomesticAnimalsPresent,
    describePeoplePresent,
    describePrivateEntry,
    prvtEntry,
    dogsPercentage,
    otherAnimalsPercentage,
    peoplePercentage,
    useStateSSR,
}) => {
    const useStateC = useStateSSR || useState;
    const [showModal, setShowModal] = useStateC({});
    const haveData = dogsPrsnt || pplePrsnt || anmlsPrsnt;

    const toggle = (present) => setShowModal({ [present]: !showModal[present] });

    if (!haveData) {
        return '';
    }

    return (
        <div className="presents px-2 px-md-0">
            <div className="mt-3">
                <DesignedForPrivate />
            </div>
            <h2 className="snif-m1 snif-semibold mt-2 mb-3">Distractions seen/heard from the spot</h2>
            <div className="row row-field">
                <Present
                    type="dogsPresent"
                    present={dogsPrsnt}
                    describe={describeDogsPresent}
                    percentages={dogsPercentage}
                    toggle={toggle}
                    ensurePrivate
                    showModal={showModal['dogsPresent']}
                />
                <Present
                    type="domesticAnimalsPresent"
                    present={anmlsPrsnt}
                    describe={describeDomesticAnimalsPresent}
                    percentages={otherAnimalsPercentage}
                    toggle={toggle}
                    showModal={showModal['domesticAnimalsPresent']}
                />
                <Present
                    type="peoplePresent"
                    present={pplePrsnt}
                    describe={describePeoplePresent}
                    percentages={peoplePercentage}
                    toggle={toggle}
                    ensurePrivate
                    showModal={showModal['peoplePresent']}
                />
                {prvtEntry && (
                    <Present
                        type="prvtEntry"
                        present={prvtEntry}
                        describe={describePrivateEntry}
                        percentages={null}
                        toggle={toggle}
                        showModal={showModal['prvtEntry']}
                    />
                )}
            </div>
            <hr />
        </div>
    );
};

export default ListingPresent;

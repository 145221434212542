import React, { useState } from 'react';
import loadable from '@loadable/component';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import { AMENITIES_CATEGORIES } from '../../consts/spot.consts';
import SpotAmenitiesIcon from '../spot-amenities-icon';
import SniffButton from '../sniff-button';
import './index.scss';

const ModalMobile = loadable(() => import('../../components/modal-mobile'));
const ModalMobileSSR = loadable(() => import('../../components/modal-mobile/modal-mobile-ssr'));
const SubAmenities = loadable(() => import('../amenities-sub-category'));
const MobileSubPage = loadable(() => import('../mobile-sub-page'));

export default function ListingAmenities({
    isMobileSSR,
    useEffectSSR,
    useStateSSR,
    dogsAmenities = [],
    essentialAmenities = [],
    peopleAmenities = [],
    venueAmenities = [],
}) {
    const ModalMobileC = useStateSSR ? ModalMobileSSR : ModalMobile;
    const useStateC = useStateSSR || useState;
    const isMobile = useStateSSR ? isMobileSSR : useMobileScreen();
    const [showModal, setShowModal] = useStateC(false);
    const myAmenities = [...dogsAmenities, ...essentialAmenities, ...peopleAmenities, ...venueAmenities].sort(
        (a, b) => a.spotOrder - b.spotOrder
    );

    const toggleModal = () => setShowModal(!showModal);

    if (myAmenities.length <= 0) {
        return '';
    }

    return (
        <div className="px-2 px-md-0">
            <h2 className="snif-m1 snif-semibold mb-3 mt-3">Amenities</h2>
            <div className="amenities-list row">
                {myAmenities.slice(0, 6).map((a, i) => {
                    return (
                        <div className="col-12 col-md-6" key={i}>
                            <SpotAmenitiesIcon amenities={a} />
                        </div>
                    );
                })}
            </div>
            {myAmenities.length > 6 && (
                <SniffButton color="secondary" size="lg" className="spot-amenities-button" onClick={toggleModal}>
                    Show all {myAmenities.length} amenities
                </SniffButton>
            )}
            <hr />
            {isMobile ? (
                <MobileSubPage
                    title="Amenities"
                    show={showModal}
                    onBack={toggleModal}
                    mBottom={96}
                    {...{ useEffectSSR, useStateSSR }}
                >
                    <div className="modal-full-amenities-list">
                        <SubAmenities amenities={essentialAmenities} title={AMENITIES_CATEGORIES.ESSENTIAL} guest />
                        <SubAmenities amenities={dogsAmenities} title={AMENITIES_CATEGORIES.DOGS} guest />
                        <SubAmenities amenities={peopleAmenities} title={AMENITIES_CATEGORIES.PEOPLE} guest />
                        <SubAmenities amenities={venueAmenities} title={AMENITIES_CATEGORIES.VENUE} guest />
                    </div>
                </MobileSubPage>
            ) : (
                <ModalMobileC title="Amenities" isOpen={showModal} toggle={toggleModal}>
                    <div className="modal-full-amenities-list">
                        <SubAmenities amenities={essentialAmenities} title={AMENITIES_CATEGORIES.ESSENTIAL} guest />
                        <SubAmenities amenities={dogsAmenities} title={AMENITIES_CATEGORIES.DOGS} guest />
                        <SubAmenities amenities={peopleAmenities} title={AMENITIES_CATEGORIES.PEOPLE} guest />
                        <SubAmenities amenities={venueAmenities} title={AMENITIES_CATEGORIES.VENUE} guest />
                    </div>
                </ModalMobileC>
            )}
        </div>
    );
}

import React from 'react';
import useWindowScroll from '../../services/hooks/useWindowScroll';
import ReservationPanel from '../../components/reservation-panel';
import MobilePhotoGallery from './mobile-photo-gallery';
import SingleViewPhoto from './single-view-photo';
import SvgChevronLeft from 'src/assets/svg/SvgChevronLeft.svg';

const RenderMobilePhotoGallery = ({
    spot,
    singleViewPhoto,
    singlePhotoHandler,
    setMobilePhotoCount,
    setMobilePhotoGallery,
    setSingleViewPhoto,
    mobilePhotoCount,
    waitInfo,
    handleBook,
    handleSwipeMobile,
}) => {
    const scrollTop = useWindowScroll();

    return (
        <div
            className="mobile-photo-gallery"
            style={singleViewPhoto !== null ? { height: '100dvh', overflowY: 'auto' } : {}}
        >
            <div className="gallery-header">
                <span
                    className="back-ico"
                    onClick={() => {
                        if (singleViewPhoto === null) {
                            setMobilePhotoCount(1);
                            setMobilePhotoGallery(false);
                            scrollTop();
                        } else {
                            setSingleViewPhoto(null);
                        }
                    }}
                >
                    <SvgChevronLeft height={14} />
                </span>
                <p className="snif-s1 snif-medium mb-0">Spot pictures</p>
            </div>
            {singleViewPhoto === null && spot.spotPhotos && (
                <MobilePhotoGallery
                    singlePhotoHandler={singlePhotoHandler}
                    mobilePhotoCount={mobilePhotoCount}
                    photos={spot.spotPhotos}
                    hostId={spot.host.id}
                    title={spot.title}
                    state={spot.state}
                    city={spot.city}
                />
            )}
            {singleViewPhoto !== null && (
                <SingleViewPhoto
                    hostId={spot.host.id}
                    {...{ spot, handleSwipeMobile, mobilePhotoCount, singleViewPhoto }}
                />
            )}
            <ReservationPanel
                commentsNmb={spot.reviewsCount}
                title={spot.title}
                rating={spot.rating}
                price={spot.price}
                wait={waitInfo}
                id={spot.id}
                live={spot.live || spot.host.id > 0}
                handleBook={handleBook}
            />
        </div>
    );
};

export default RenderMobilePhotoGallery;

import React from 'react';
import { selectText } from './utils';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import { SPOT_PRESENCE_STATE } from '../../consts/spot.consts';
import DesignedForPrivate from '../designed-for-private';
import SniffButton from '../sniff-button';
import ModalMobile from '../modal-mobile';
import SvgAnaliticsBar from 'src/assets/svg/SvgAnaliticsBar.svg';
import SvgChevronRight from 'src/assets/svg/SvgChevronRight.svg';
import SvgChicken from 'src/assets/svg/SvgChicken.svg';
import SvgDog from 'src/assets/svg/SvgDog.svg';
import SvgDoor from 'src/assets/svg/SvgDoor.svg';
import SvgPeopleGroup from 'src/assets/svg/SvgPeopleGroup.svg';

const presentsTitle = {
    dogsPresent: ['Other dogs', <SvgDog key="dogs" className="icon-title" />],
    domesticAnimalsPresent: ['Other domestic animals', <SvgChicken key="animals" className="icon-title" />],
    peoplePresent: ['Other people', <SvgPeopleGroup key="people" className="icon-title" />],
    prvtEntry: ['Private entry', <SvgDoor key="private" className="icon-title" />],
};

export const Present = ({ present, describe, showModal, toggle, type, ensurePrivate, percentages }) => {
    const isMobile = useMobileScreen();

    const handleToggle = () => (isMobile ? toggle(type) : {});

    if (!present) {
        return '';
    }

    return (
        <div className="col-12 col-md-6 present-block">
            <div className="field snif-semibold d-flex" onClick={handleToggle}>
                <span>{presentsTitle[type][1]}</span>
                <p className="mb-0">
                    <span className="d-block mb-1">{presentsTitle[type][0]}</span>
                    <span className={`snif-medium ${selectText(present)[1]} ${type === 'prvtEntry' ? 'prvt' : ''}`}>
                        {selectText(present)[0]}
                    </span>
                    <SvgChevronRight className="icon-info" onClick={() => toggle(type)} />
                    <span className="present-chev-r">
                        <SvgChevronRight />
                    </span>
                </p>
            </div>
            <ModalMobile
                isOpen={showModal}
                toggle={toggle}
                title={`${presentsTitle[type][0]}`}
                className="present-modal"
            >
                <p
                    className={`snif-p snif-semibold ${selectText(present)[1]} ${
                        type === 'prvtEntry' ? 'prvt' : ''
                    } mb-2`}
                >
                    {SPOT_PRESENCE_STATE[present]}
                </p>
                <p className="snif-p mb-3 text-pre">{describe}</p>
                {percentages !== null && (
                    <p className="field percentage snif-p mb-2">
                        <SvgAnaliticsBar width={16} height={16} className="icon" />
                        Seen/heard by <span className="snif-semibold">{percentages}% of guests</span>
                    </p>
                )}
                {ensurePrivate && <DesignedForPrivate />}
                <SniffButton className="mt-3" color="secondary" block="mobile" size="lg" onClick={toggle}>
                    Ok, close
                </SniffButton>
            </ModalMobile>
        </div>
    );
};

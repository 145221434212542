import React from 'react';
import { PREVIEW_TYPES } from '../../consts/images.const';
import SpotListing from '../../components/spot-listing/listing-ssr';
import ListingItem from '../../components/listing-item/item-ssr';

const RenderNearbySpots = ({ spot, useEffect, useState, usePrevious }) => {
    const items = [];

    spot.nearbySpots.map((v, i) => {
        if (i < 3) {
            if (items[0]) {
                items[0].spots.push(v);
            } else {
                items[0] = { key: i, spots: [v] };
            }
        } else if (i < 6) {
            if (items[1]) {
                items[1].spots.push(v);
            } else {
                items[1] = { key: i, spots: [v] };
            }
        } else if (i < 9) {
            if (items[2]) {
                items[2].spots.push(v);
            } else {
                items[2] = { key: i, spots: [v] };
            }
        } else {
            if (items[3]) {
                items[3].spots.push(v);
            } else {
                items[3] = { key: i, spots: [v] };
            }
        }
    });

    if (!spot.nearbySpots || !spot.nearbySpots.length) {
        return '';
    }

    return (
        <>
            <div className="nearby-spots-mobile container d-block d-md-none">
                <hr className="mt-4" />
                <div className="snif-m1 snif-semibold mt-3">Other spots nearby</div>
                <SpotListing
                    className="spot-detail-nearby"
                    items={spot.nearbySpots}
                    isMobile={true}
                    disableSwipe
                    noAskForAlert
                    homePage
                    inRow
                    showDesc
                    useState={useState}
                    useEffect={useEffect}
                    usePrevious={usePrevious}
                    noRich
                />
            </div>
            <div className="nearby-spots container d-none d-md-block">
                <div className="snif-m1 snif-semibold mt-4 mb-3">Other spots nearby</div>
                <div className="car-slide car-slide-ssr">
                    {spot.nearbySpots.map((val, idx) => {
                        return (
                            <ListingItem
                                key={idx}
                                type={PREVIEW_TYPES.PREVIEW}
                                target="_blank"
                                item={val}
                                disableSwipe
                                disableArrow
                                noAskForAlert
                                homePage
                                inRow
                                showDesc
                                useState={useState}
                                useEffect={useEffect}
                                noRich
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default RenderNearbySpots;

import React, { useCallback, useMemo, useRef } from 'react';
import moment from 'moment-timezone';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import { ImageHelper } from '../../classes/image-helper.class';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { SPOT_MIN_REQ_PHOTOS } from '../../consts/spot.consts';
import RequestMorePhotos from '../request-more-photos';
import PictureSet from '../picture-set';
import SvgChevronRight from 'src/assets/svg/SvgChevronRight.svg';
import SvgCross2 from 'src/assets/svg/SvgCross2.svg';
import '@splidejs/splide/dist/css/splide.min.css';
import './carousel-ssr.scss';

const ArrowBtn = ({ activeIndex, length, showArrow, next, prev }) => {
    if (!showArrow || length == 1) {
        return '';
    }

    return (
        <div className="mysplide__arrows">
            <button
                className="mysplide__arrow mysplide__arrow--prev"
                style={{ visibility: activeIndex == 0 ? 'hidden' : 'visible' }}
                onClick={prev}
            >
                <SvgChevronRight />
            </button>
            <button
                className="mysplide__arrow mysplide__arrow--next"
                style={{ visibility: activeIndex == length - 1 ? 'hidden' : 'visible' }}
                onClick={next}
            >
                <SvgChevronRight />
            </button>
        </div>
    );
};

const ReqPhotos = ({ id }) => (
    <SplideSlide>
        <div className="car-ssr-req-container">
            <RequestMorePhotos id={id} />
        </div>
    </SplideSlide>
);

const Slides = ({ item, images, useState, lazy, onClick, rawImg, removePhoto, isShowReq, imageAlt }) => {
    const altImg = item.title ? `${item.title} - ${item.city}, ${item.state}` : imageAlt || 'Spot thumbnail';

    const photoSlides = images.map((img, index) => {
        const methodDef = index == 0 ? lazy : 'lazy';
        const priorityDef = index == 0 ? 'high' : 'auto';
        const imgUrl = rawImg
            ? URL.createObjectURL(img.images)
            : ImageHelper.getUrlFromOptions(img, `full-fit-in/${IMG_RESIZES.x768}`);

        return (
            <SplideSlide key={img.id || index}>
                <PictureSet
                    imgs={imgUrl}
                    data-splide-lazy={imgUrl}
                    className="img carousel-spot-inner"
                    alt={altImg}
                    method={methodDef}
                    fetchpriority={priorityDef}
                    onClick={onClick}
                    useState={useState}
                    width={468.76}
                    height={300}
                    SSR
                />
                {rawImg && (
                    <div className="raw-img-delete-photo" onClick={() => removePhoto(index)}>
                        <SvgCross2 />
                    </div>
                )}
            </SplideSlide>
        );
    });

    return isShowReq ? [...photoSlides, <ReqPhotos key="req" id={item.id} />] : photoSlides;
};

const CarouselSSR = ({
    item,
    useState,
    useEffect,
    lazy,
    onClick = () => {},
    showArrow = true,
    server,
    rawImg,
    removePhoto,
    spotGallery,
    hostId,
    singleViewPhoto,
    disableSwipe,
    imageAlt,
}) => {
    const [loaded, setLoaded] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [splideRefNode, setSplideRefNode] = useState(null);
    const images = item.allPhotos || item.spotPhotos || item.photos || [item.thumbPhoto];
    const [maxLimit, setMaxLimit] = useState(singleViewPhoto ? singleViewPhoto : images.length > 1 ? 5 : images.length);
    const length = spotGallery && images.length < SPOT_MIN_REQ_PHOTOS ? images.length + 1 : images.length;
    const isShowReq = spotGallery && images.length < SPOT_MIN_REQ_PHOTOS;
    const myImages = useMemo(() => images.slice(0, maxLimit), [maxLimit]);
    const creatorName = `${item?.host?.nameAndInitial} | Sniffspot` || undefined;

    const splideRef = spotGallery
        ? useRef(null)
        : useCallback((node) => {
              if (singleViewPhoto >= 0) {
                  node?.splide.go(singleViewPhoto);
              }
              setSplideRefNode(node);
          }, []);

    const next = (e) => {
        e.preventDefault();
        if (splideRef.current) {
            splideRef.current.splide.go('+1');
        } else {
            splideRefNode.splide.go('+1');
        }
    };

    const prev = (e) => {
        e.preventDefault();
        if (splideRef.current) {
            splideRef.current.splide.go('-1');
        } else {
            splideRefNode.splide.go('-1');
        }
    };

    let submittedBy = '';
    const photo = images[activeIndex];

    if (hostId && photo.providedById !== null && photo.providedBy && hostId !== photo.providedBy?.id) {
        const monthYear = moment(photo.createdAt).format('MMM YYYY');
        submittedBy = `Submitted by: ${photo.providedBy.firstname} ${photo.providedBy.lastInitial}. - ${monthYear}`;
    }

    useEffect(() => {
        if (activeIndex > maxLimit - 4 && maxLimit < length) {
            setMaxLimit((limit) => (limit + 5 > length ? length : limit + 5));
        }
    }, [activeIndex]);

    useEffect(() => {
        setLoaded(true);
    }, []);

    if (!loaded) {
        return (
            <div
                className="carousel__photo-container p-lcp"
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                }}
            >
                <div
                    className="splide splide--slide splide--ltr splide--draggable is-active is-overflow is-initialized"
                    style={{ height: '100%' }}
                >
                    <div
                        className="splide__track splide__track--slide splide__track--ltr splide__track--draggable"
                        style={{ height: '100%', paddingLeft: '0px', paddingRight: '0px' }}
                    >
                        <ul className="splide__list">
                            <li className="splide__slide is-active is-visible w-100">
                                <PictureSet
                                    imgs={
                                        rawImg
                                            ? URL.createObjectURL(images[0].images)
                                            : ImageHelper.getUrlFromOptions(
                                                  images[0],
                                                  `full-fit-in/${IMG_RESIZES.x768}`
                                              )
                                    }
                                    className="img carousel-spot-inner"
                                    alt={
                                        item.title
                                            ? `${item.title} - ${item.city}, ${item.state}`
                                            : imageAlt || 'Spot thumbnail'
                                    }
                                    method={server ? 'eager' : 'lazy'}
                                    fetchpriority={server ? 'high' : 'auto'}
                                    creatorName={creatorName}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            <Splide
                ref={splideRef}
                options={{
                    focus: 'center',
                    rewind: true,
                    pagination: false,
                    arrows: false,
                    lazyLoad: 'nearby',
                    flickMaxPages: 1,
                    drag: !disableSwipe,
                }}
                onMove={(_, idx) => setActiveIndex(idx)}
            >
                <Slides
                    {...{ item, images: myImages, useState, lazy, onClick, rawImg, removePhoto, isShowReq, imageAlt }}
                />
            </Splide>
            <ArrowBtn {...{ activeIndex, length, showArrow, next, prev }} />
            {myImages.length >= 1 && (
                <div className="spot-detail-photos-counter">
                    <div className="photos-text">
                        {activeIndex + 1} of {length}
                    </div>
                </div>
            )}
            {hostId && submittedBy && (
                <div className="photos-submitted-box">
                    <p className="photos-submitted">{submittedBy}</p>
                </div>
            )}
        </>
    );
};

export default CarouselSSR;
